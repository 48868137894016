import React, { useState, useEffect } from "react";
import { useApolloClient, useMutation } from "@apollo/react-hooks";
import { Icon, Upload } from "antd";

import { POST_ADD_MEDIA, MEDIA_VIDEO_ADD, POST_DELETE_MEDIA } from "graphql/mutation/post-gql";
import { GET_POST_SHORT } from "graphql/query/post-gql";

import { errorNotification, successNotification } from "components/result";
import Icons from "components/icons";
import { Localize } from "components/service";

import './post-upload-media.css';


const PostUploadMedia = ({ defaultMedia, postId, postSlug }) => {

    const client = useApolloClient();

    const [addMedia, { loading: loadingQueryWithImage }] = useMutation(POST_ADD_MEDIA);
    const [ createPostWithVideo, { loading: loadingQueryWithVideo } ] = useMutation(MEDIA_VIDEO_ADD);
    const [deleteMedia] = useMutation(POST_DELETE_MEDIA);

    const [ filesLoading, setFilesLoading ] = useState(0);

    useEffect(() => {

        const parentElement = document.querySelector('.ant-upload-list.ant-upload-list-picture-card');

        if (parentElement) {
            const elements = parentElement.querySelectorAll('.ant-upload-list-picture-card-container');
            elements.forEach(element => {
                const linkElement = element.querySelector('.ant-upload-list-item-name');
                const link = linkElement.getAttribute('href');

                if (/(\.mp4|\.AVI|\.MPEG|\.WMV|\.MKV|\.3GP|\.WEBM|\.MOV)$/i.test(link)) {
                    element.classList.add('video-item');
                }
            });
        }
    }, [defaultMedia]);

    const fileList = defaultMedia.map(({ id, title, thumbnail, path, extension }) => {
        return (
            {
                uid : id,
                name : title,
                url : path,
                thumbUrl: thumbnail,
                extension,
                status: 'done',
            }
        )
    });

    const loading = loadingQueryWithImage || loadingQueryWithVideo;

    const formUploadProps = {
        accept: 'image/*,video/*',
        beforeUpload: (file) => {

            setFilesLoading((prevState) => prevState + 1);

            if (file.type.startsWith('video/')) {
                createPostWithVideo({
                    variables: {
                        post_id: postId,
                        file
                    },
                    update(cache, { data }) {
                        const { mediaVideoAdd } = data;
                        const { label, message } = mediaVideoAdd;

                        setFilesLoading((prevState) => prevState - 1);

                        successNotification({
                            title: label,
                            description: message
                        });
                    }
                }).catch((error) => {
                    setFilesLoading((prevState) => prevState - 1);
                    errorNotification(error);
                });
            } else {
                addMedia({
                    variables: {
                        post_id: postId,
                        files: [file]
                    },
                    update(cache, { data }) {

                        const { addMedia } = data;
                        const { label, message } = addMedia;

                        setFilesLoading((prevState) => prevState - 1);

                        successNotification({
                            title: label,
                            description: message
                        });
                    }
                }).catch((error) => {
                    setFilesLoading((prevState) => prevState - 1);
                    errorNotification(error);
                });
            }

            return false;
        },
        onRemove: (file) => {

            if(file.status === 'done' && defaultMedia.length > 1){

                deleteMedia({
                    variables : {
                        media_id : file.uid
                    },
                    update: (cache, { data }) => {

                        const {
                            deleteMedia : {
                                label,
                                message
                            }
                        } = data;

                        const {post} = client.readQuery({
                            query: GET_POST_SHORT,
                            variables: {
                                slug: postSlug,
                            }
                        });

                        const newMedia = post.media.filter(item => item.id !== file.uid);

                        client.writeQuery({
                            query: GET_POST_SHORT,
                            data: {
                                post: { ...post, media:[ ...newMedia ] }
                            },
                        });

                        successNotification({
                            title: label,
                            description: message
                        });

                    }
                }).catch((error) => {
                    errorNotification(error);
                });

            }
        },
        fileList: fileList,
        listType: "picture-card",
        showUploadList: {
            showPreviewIcon: false,
            showDownloadIcon: false
        },
        multiple : true
    };

    return(
        <div className={ `post-update-media ${ fileList.length < 3 ? "center-upload" : ""}`}>
            <Localize wrap="h4" mediaCount={ defaultMedia.length }>
                POST.Media_Count
            </Localize>
            <Upload {...formUploadProps}>
                <div className="upload-label">
                    { loading || filesLoading > 0 ?
                        <Icon type="loading" /> :
                        <>
                            <div className="row">
                                <span className="icon">
                                    <Icons.PlusNewImage />
                                </span>
                                <span className="text">
                                    <Localize>POST.Text_AddImage</Localize>
                                </span>
                            </div>
                            <div className="row">
                                <span className="icon">
                                    <Icons.VideoSroke />
                                </span>
                                <span className="text">
                                    <Localize>POST.Text_AddVideo</Localize>
                                </span>
                            </div>

                        </>
                    }
                </div>
            </Upload>
        </div>
    );
};

export default PostUploadMedia;